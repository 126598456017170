<template>
  <div class="select-box d-flex flex-column align-items-start">
    <perfect-scrollbar ref="scrollbar" id="scrollbar-select-box" @scroll="shadow? customScroll($event): null">
      <div class="form-group"
           v-for="(item, index) in checkData"
           :class="{'mb-4': index + 1 !== checkData.length}"
           :key="item.value">
        <template v-if="!item.clearAction">
          <input v-model="selected"
                 @change="updateVal"
                 :value="item.value"
                 :type="type ? type : 'checkbox'"
                 :id="item.text">
          <label :for="item.text">{{ item.text }}</label>
        </template>
        <template v-else>
          <input v-model="selected"
                 @change="clearAction(item.value)"
                 :value="item.value"
                 :type="type ? type : 'checkbox'"
                 :id="item.text">
          <label :for="item.text">{{ item.text }}</label>
        </template>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
let clearVal = [];
let elements = [];
export default {
  name: "SelectBox",
  props: {
    checkData: {
      required: true,
      type: Array
    },
    type: {
      required: false,
      type: String
    },
    shadow: {
      required: false,
      type: Boolean
    },
  },
  data() {
    return {
      selected: []
    }
  },
  mounted() {
    clearVal = this.checkData.filter(e => e.clearAction)
    if (this.shadow) {
      elements = document.querySelectorAll('.form-group')
      let a = this.$refs.scrollbar.$el.clientHeight / (elements[0].offsetHeight * 2)
      a = a.toFixed(0)
      elements[+a - 1].style.opacity = '0.5'
      elements = [...elements].map(e => e.offsetTop);
    }
  },
  methods: {
    customScroll(ev) {
      if ((ev.target.scrollTop + ev.target.clientHeight + 10) >= ev.target.scrollHeight) {
        document.querySelectorAll('.form-group').forEach(e => {
          e.style.opacity = '1'
        })
        return false;
      }
      let a = elements.filter(e => e < ev.target.scrollTop + ev.target.clientHeight)
      let elementVisibleIndex = elements.indexOf(a[a.length - 1])
      document.querySelectorAll('.form-group').forEach(e => {
        e.style.opacity = '1'
      })
      document.querySelectorAll('.form-group')[elementVisibleIndex].style.opacity = '0.5'
    },
    updateVal() {
      if (clearVal.length) this.selected = this.selected.filter(e => e !== clearVal[0].value)
      this.$emit('update-val', this.selected)
      sessionStorage.setItem(this.$route.name, JSON.stringify(this.selected))
    },
    checkAll() {
      this.selected = this.checkData.filter(e => !e.clearAction).map(el => el.value)
      this.$emit('update-val', this.selected)
      sessionStorage.setItem(this.$route.name, JSON.stringify(this.selected))
    },
    clearAction(val) {
      this.selected = this.selected.filter(e => e === val)
      this.$emit('update-val', this.selected)
      sessionStorage.setItem(this.$route.name, JSON.stringify(this.selected))
    },
    setVal(val) {
      this.selected = val
    }
  },
}
</script>

<style lang="scss" scoped>
.ps {
  padding-left: 30px;
  margin-left: -30px;
  max-height: 230px;
  @media (max-width: 340px) {
    max-height: 130px;
    padding-left: 40px;
  }
  @media (min-width: 341px) and (max-width: 767px) {
    max-height: 330px;
    padding-left: 21px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    max-height: 200px;
  }
  @media (min-width: 1600px) and (min-height: 1080px) {
    max-height: 370px;
  }
  @media (min-width: 1920px) {
    max-height: 370px;
  }
  // ipad
  @media (min-width: 768px) and (max-width: 1024px) and (min-height: 900px){
    max-height: 370px;
  }
}

.select-box {
  padding-left: 10px;
  position: relative;
  @media (max-width: 767px) {
    padding-left: 20px;
  }

  .shadow-box {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: block;
    filter: blur(10px);
    z-index: 999;
    @media (min-width: 769px) {
      filter: blur(10px);
      opacity: 0.5;
      height: 30%;
      background: linear-gradient(to right, rgba(194, 202, 185, 1) 0%, rgba(221, 200, 160, 1) 50%, rgba(233, 182, 176, 1) 100%);
    }
  }
}

.form-group {
  display: block;
  margin-bottom: 15px;
  @media (max-width: 340px) {
    margin-bottom: 0;
  }
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
  font-weight: 500;
  color: #343c71;
  letter-spacing: -0.5px;
  display: flex;
  align-items: center;
}

.form-group label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: solid 1px rgba(#343c71, 0.2);
  border-radius: 4px;
  min-width: 24px;
  min-height: 24px;
  max-height: 24px;
  max-width: 24px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  left: -10px;
}

.form-group input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  left: -10px;
  height: 24px;
  background-image: url("../assets/img/check-box/checkbox-active.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
